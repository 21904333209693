import "core-js/modules/es.array.push.js";
// @ is an alias to /src
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getLatestList, getRepairWorkOrderStatus, getFaultLevel, getRepairWorkOrderStatusStatistics, getUserInfo, revokeAcceptEvaluateRecord } from "@/api/api";
import { Dialog, Notify } from "vant";
import { baseURL } from "./../../public/config";
export default {
  name: "HomeView",
  setup() {
    let headImg = ref("");
    // 筛选
    let showFilter = ref(false);
    let showCalendar = ref(false);
    let timeCalendar = ref(""); //报修时间
    let status = ref(undefined); //状态
    let levelId = ref(undefined); //故障等级
    let startReportedFaultDate = ref(undefined); //起止时间
    let endReportedFaultDate = ref(undefined); //时间
    let statusOption = ref([]);
    let levelOption = ref([]);
    let list = ref([]);
    let showReq = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    let itemID = ref(null);
    const isLogin = ref(false);
    const router = useRouter();
    const route = useRoute();
    const delIndex = ref(0);
    const page = ref(0);
    const pageSize = ref(10);
    const disableEdit = ref(false);
    const queryString = ref("");
    const totallist = ref([{
      label: "待接单",
      value: 0
    }, {
      label: "待处理",
      value: 0
    }, {
      label: "维修中",
      value: 0
    }, {
      label: "待验收",
      value: 0
    }]);
    const allStatus = ref([]);
    const onLoad = () => {
      if (isLogin.value) {
        // list.value=[]
        getList();
      }
    };
    const getList = () => {
      page.value = page.value + 1;
      let obj = {
        repairWorkOrderStatus: status.value,
        faultLevel: levelId.value,
        startReportedFaultDate: startReportedFaultDate.value,
        endReportedFaultDate: endReportedFaultDate.value,
        page: page.value,
        pageSize: pageSize.value,
        queryString: queryString.value
      };
      getStatistics();
      getLatestList(obj).then(res => {
        let data = res.data.data;
        list.value = list.value.concat(data.items);
        // 加载状态结束
        loading.value = false;
        if (list.value.length == data.totalCount) {
          finished.value = true;
        }
      });
    };
    const getStatus = () => {
      getRepairWorkOrderStatus().then(res => {
        let data = res.data.data;
        data.splice(0, 0, {
          name: "全部",
          id: undefined
        });
        statusOption.value = data.map(item => {
          return {
            text: item.name,
            value: item.id
          };
        });
      });
    };
    const getLevel = () => {
      getFaultLevel().then(res => {
        let data = res.data.data;
        let defult = [{
          name: "全部",
          id: undefined
        }];
        data = defult.concat(data);
        levelOption.value = data.map(item => {
          return {
            text: item.name,
            value: item.id
          };
        });
      });
    };
    const getStatistics = () => {
      let obj = {
        page: 1,
        pageSize: 9999
      };
      getRepairWorkOrderStatusStatistics(obj).then(res => {
        let data = res.data.data;
        totallist.value = [];
        loading.value = false;
        allStatus.value = data.map(item => {
          return {
            text: item.name,
            value: item.id
          };
        });
        data.map(item => {
          if (item.id == 1 || item.id == 2 || item.id == 3 || item.id == 4) {
            totallist.value.push(item);
          }
        });
      });
    };
    const chooseTime = () => {
      showCalendar.value = true;
    };
    const searchFilter = () => {
      showFilter.value = false;
      list.value = [];
      page.value = 0;
      getList();
    };
    const onSearch = () => {
      list.value = [];
      page.value = 0;
      getList();
    };
    const clear = () => {
      timeCalendar.value = "";
      startReportedFaultDate.value = undefined;
      endReportedFaultDate.value = undefined;
      status.value = undefined;
      levelId.value = undefined;
    };
    const onConfirm = dates => {
      showCalendar.value = false;
      const [start, end] = dates;
      startReportedFaultDate.value = formatDate(start);
      endReportedFaultDate.value = formatDate(end);
      timeCalendar.value = `${formatDate(start)} - ${formatDate(end)}`;
    };
    const formatDate = date => `${date.getYear() + 1900}/${date.getMonth() + 1}/${date.getDate()}`;
    const getDelItemId = id => {
      itemID.value = id;
    };
    // const getDelEle = (ele => ele.id === itemID.value)
    // 删除列表
    const delItem = () => {
      let data = {
        id: itemID.value
      };
      revokeAcceptEvaluateRecord(data).then(res => {
        if (res.data.success) {
          page.value = 0;
          list.value = [];
          // list.value.splice(list.value.findIndex(getDelEle), 1)
          getList();
        }
      });
    };
    const add = () => {
      if (isLogin.value) {
        router.push({
          path: "/add"
        });
      } else {
        Notify({
          type: "warning",
          message: "您还未登录哦，请联系管理员"
        });
      }
    };
    // 获取用户信息
    const getUserInfoFun = code => {
      let obj = {
        authCode: code
      };
      getUserInfo(obj).then(res => {
        sessionStorage.clear();
        let token = res.data.data.token;
        sessionStorage.setItem("authorization", JSON.stringify(res.data.data));
        headImg.value = res.data.data.wechatAccountInfo.headimgurl;
        if (token == null) {
          router.replace({
            path: "/login"
          });
        } else {
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("regionalId", res.data.data.regional?.id);
          isLogin.value = true;
          getStatistics();
          // getList()
          getStatus();
          getLevel();
        }
      }).catch(() => {
        Notify({
          type: "danger",
          message: "微信授权登录失败"
        });
      });
    };
    //模拟微信用户信息
    // const getDefultUserInfoFun = () => {
    //   sessionStorage.clear();
    //   let data = {
    //     token:
    //       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWRtaW4iLCJpZCI6IjEiLCJyb2xlX2lkIjoiMSwxMDEiLCJiaW5kX3JlZ2lvbmFsX2lkIjoiMTAxLDEsMiwzLDQsNSw2LDcsOCw5LDEwLDExLDEyLDEzLDE0LDE1IiwiZG1fYmluZF9yZWdpb25hbF9pZCI6IjEwMSwxLDIsMyw0LDUsNiw3LDgsOSIsInJlZ2lvbmFsX2lkIjoiMTAxIiwiZXhwIjoxNzIxNTI3NDYzLCJpc3MiOiJsay5jc3NkLmNvbSIsImF1ZCI6ImxrLmNzc2QuZW1zLmFwaSJ9.kl-wj8fRpvkQg0l-6yHc314h-U_Q0E0FNwh3dRoo-A4",
    //     expireTime: "2024-07-20T10:51:56.8718565+08:00",
    //     id: 1081,
    //     userName: "13658441254",
    //     displayName: "admin",
    //     department: "admin",
    //     telePhone: "13658441254",
    //     roles: [],
    //     regional: {
    //       id: 101,
    //       name: "老肯医疗",
    //       simplifiedName: "老肯医疗",
    //       isEnable: true,
    //     },
    //     regionals: [],
    //     dMRegionals: [
    //       {
    //         id: 101,
    //         name: "老肯医疗",
    //         simplifiedName: "老肯医疗",
    //         isEnable: true,
    //       },
    //     ],
    //     wechatAccountInfo: {
    //       unionid: "o075U6I4u8sWIFkf10b3o1qXpEvw",
    //       nikeName: "Zhou",
    //       headimgurl:
    //         "https://thirdwx.qlogo.cn/mmopen/vi_32/IMQMDvfl1v9lj4o8ByrhGl3MbHoVEJYTFhBicPiaPn94IZX0Wp94JwG8MDuIVEuXs9qQvzFATV4pg6YcjfYeAYzt5MBCGUfQUD6uXJdiceIXRI/132",
    //     },
    //   };
    //   let token = data.token;
    //   Notify(token);
    //   sessionStorage.setItem("authorization", JSON.stringify(data));
    //   headImg.value = data.wechatAccountInfo.headimgurl;
    //   sessionStorage.setItem("regionalId", data.regional?.id);
    //   sessionStorage.setItem("token", token);
    //   isLogin.value = true;
    //   getStatistics();

    //   // getList()
    //   getStatus();
    //   getLevel();
    // };

    const onClickButton = () => {
      showFilter.value = true;
    };
    const addItem = () => {
      router.push({
        path: "/add"
      });
    };
    const check2DCode = id => {
      router.push({
        path: "/2DCode",
        query: {
          id: id
        }
      });
    };
    const showDetail = id => {
      router.push({
        path: "/detail",
        query: {
          id: id
        }
      });
    };
    const beforeClose = ({
      position
    }) => {
      switch (position) {
        case "left":
        case "outside":
          return true;
        case "right":
          return new Promise(resolve => {
            Dialog.confirm({
              title: '提示',
              message: "此操作将撤销该报修单, 是否确认?"
            }).then(() => {
              delItem();
            });
            resolve;
          });
      }
    };
    onMounted(() => {
      isLogin.value = false;
      showFilter.value = false;
      localStorage.clear();
      let authorization = JSON.parse(sessionStorage.getItem("authorization"));
      localStorage.setItem("baseUrl", baseURL);
      let code = route.query.code;
      let token = sessionStorage.getItem("token");
      if (code && !token) {
        sessionStorage.setItem("code", code);
        getUserInfoFun(code);
      } else {
        // Notify({ type: "warning", message: "您还未登录哦！"});
        // getDefultUserInfoFun()
      }
      if (token) {
        isLogin.value = true;
        headImg.value = authorization.wechatAccountInfo.headimgurl;
        disableEdit.value = false;
        getStatus();
        getLevel();
      }
    });
    return {
      disableEdit,
      headImg,
      isLogin,
      list,
      delIndex,
      showReq,
      add,
      getDelItemId,
      onClickButton,
      delItem,
      beforeClose,
      check2DCode,
      addItem,
      showDetail,
      showFilter,
      totallist,
      allStatus,
      levelOption,
      levelId,
      status,
      statusOption,
      showCalendar,
      chooseTime,
      onConfirm,
      timeCalendar,
      minDate: new Date(2010, 0, 1),
      clear,
      searchFilter,
      onLoad,
      finished,
      loading,
      onSearch,
      queryString
    };
  }
};