import { createApp } from 'vue'
import App from './App.vue'
// import axios from 'axios'
import VueScrollTo from 'vue-scrollto';
import router from './router'
import store from './store'
import { ConfigProvider,NavBar,Button,Form, Field,RadioGroup, Radio,Calendar,DatetimePicker,Picker,Popup,Icon,Overlay,Toast,Skeleton,Empty,Loading,Notify,SwipeCell,Dialog,Cell, CellGroup} from 'vant';
import 'vant/lib/index.css';
import 'vant/es/toast/style';
import 'vant/es/notify/style';
import '@/style/iconfont/iconfont.css'

const app = createApp(App);
app.use(VueScrollTo)
app.use(ConfigProvider)
app.use(NavBar)
app.use(Button)
app.use(Form)
app.use(Field)
app.use(RadioGroup)
app.use(Radio)
app.use(Calendar)
app.use(DatetimePicker)
app.use(Picker)
app.use(Popup)
app.use(Icon)
app.use(Overlay)
app.use(Skeleton)
app.use(Empty)
app.use(Loading)
app.use(SwipeCell)
app.use(Cell)
app.use(CellGroup)
app.use(Dialog)
app.use(Toast)
app.use(Notify)

app.use(store).use(router).mount('#app')
