// import axios from 'axios'
import {http} from '@/utils/http'

// 获取维修工单状态字典
export function getRepairWorkOrderStatus(){
    return http({
		url: 'DM_H5/DictData/RepairWorkOrderStatus',
		method: 'get',
	});
}
// 获取故障等级
export function getFaultLevel(){
    return http({
		url: 'DM_H5/DictData/FaultLeve',
		method: 'get',
	});
}
export function uploadFile(data){
    return http({
		url: 'DM_H5/UploadFile',
		method: 'post',
		data
	});
}

//数据统计
export function getRepairWorkOrderStatusStatistics(data){
    return http({
		url: 'api/dm/h5/RepairWorkOrder/StatusStatistics',
		method: 'post',
		data
	});
}
// 保修单
export function getLatestList(data){
    return http({
		url: 'api/dm/h5/RepairWorkOrder/PageList',
		method: 'post',
		data
	});
}
//故障类型
export function getFaultTypeAll(){
    return http({
		url: 'api/dm/h5/FaultType/All',
		method: 'get',
	});
}
//设备状态
export function getDeviceStatus(){
    return http({
		url: 'DM_H5/DictData/DeviceStatus',
		method: 'get',
	});
}

// 新增
export function createRepairWorkOrder(data){
    return http({
		url: 'api/dm/h5/RepairWorkOrder/Create',
		method: 'post',
		data: data
	});
}
// 交接单二维码文本
export function getQrcode(id){
    return http({
		url: `api/ExternalInstrument/Qrcode/${id}`,
		method: 'get',
	});
}

// 详情
export function getRepairWorkOrderDetail(id){
    return http({
		url: `api/dm/h5/RepairWorkOrder/${id}`,
		method: 'get',
	});
}
// 获取清洗程序类型
export function getCleaningProcedureType(){
    return http({
		url: 'api/ExternalInstrument/CleaningProcedureType',
		method: 'get',
	});
}

// 获取灭菌程序类型
export function getSterilizationProcedureType(){
    return http({
		url: 'api/ExternalInstrument/SterilizationProcedureType',
		method: 'get',
	});
}
// 获取用户信息
export function getUserInfo(data){
    return http({
		url: 'api/Authentication/wechatlogin',
		method: 'post',
		data
	});
}
//新增用户
export function addWechatRegisteredUser(data){
    return http({
		url: 'api/dm/DMUser/AddWechatRegisteredUser',
		method: 'post',
		data
	});
}
//登录
export function loginH5Api(data){
    return http({
		url: 'api/Authentication/loginh5',
		method: 'post',
		data
	});
}

export function allUserRegional(){
    return http({
		url: 'DM_H5/AllEnableRegional',
		method: 'get',
	});
}


export function getHandleDetail(id){
    return http({
		url: 'api/dm/h5/RepairWorkOrder/HandleDetail?repairWorkOrderId='+id,
		method: 'get',
	});
}

export function createAcceptEvaluateRecord(data){
    return http({
		url: 'api/dm/h5/RepairWorkOrder/CreateAcceptEvaluateRecord',
		method: 'post',
		data
	});
}

export function getAcceptEvaluateRecord(id){
    return http({
		url: 'api/dm/h5/RepairWorkOrder/AcceptEvaluateRecord/'+id,
		method: 'get',
	});
}
export function getDeviceDetails(id){
    return http({
		url: 'api/dm/h5/Device/'+id,
		method: 'get',
	});
}

export function getDeviceGetDevice(number) {
	return http({
		url: 'api/dm/h5/Device/GetDevice?number=' + number,
		method: 'get',
	});
}

export function revokeAcceptEvaluateRecord(data){
    return http({
		url: 'api/dm/h5/RepairWorkOrder/Revoke',
		method: 'post',
		data
	});
}

