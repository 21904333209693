import { reactive } from 'vue';
// 
export default {
  setup() {
    const themeVars = reactive({
      buttonBorderRadius: '4px'
    });
    return {
      themeVars
    };
  }
};