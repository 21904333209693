import { createRouter, createWebHistory} from 'vue-router'//useRoute
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/login.vue'
const routes = [
  {path:'/',redirect:"/dmh5"},
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta:{
      title:''
    }
  },
  {
    path: '/dmh5',
    name: 'dmh5',
    component: HomeView,
    meta:{
      title:'我的报修'
    }
  },
  {
    path: '/tips',
    name: 'tips',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/tipsView.vue')
  },
  {
    path: '/add',
    name: 'add',
    component: () => import('../views/addView.vue'),
    meta:{
      title:'新增报修单'
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detailView.vue'),
    meta:{
      title:'报修单详情'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const agent = navigator.userAgent.toLowerCase();
router.beforeEach((to,from,next) => {
    if(to.path==='/tips')next()
    if (agent.match(/MicroMessenger/i) == "micromessenger") {
      next()
      if(to.meta.title){
        document.title = ''
      }
    } else {
      next('/tips')
    }
})

export default router
