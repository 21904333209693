// @ is an alias to /src
import { ref, onMounted, reactive } from 'vue';
//   import { useRouter } from 'vue-router';
import { Notify } from 'vant';
import { loginH5Api, allUserRegional } from '@/api/api';
export default {
  name: "HomeView",
  setup() {
    // const router = useRouter()
    // const route = useRoute()
    let headImg = ref('');
    let showPicker = ref(false);
    let columns = ref(["选项1", "选项2"]);
    let form = reactive({
      password: "",
      regionalId: null,
      regionalText: "",
      userName: ""
    });
    const showPIckerType = () => {
      showPicker.value = true;
    };
    const onConfirmPicker = data => {
      showPicker.value = false;
      form.regionalId = data.value;
      form.regionalText = data.text;
    };
    const allUserRegionalFun = () => {
      allUserRegional().then(res => {
        columns.value = res.data.data.map(item => {
          return {
            text: item.name,
            value: item.id
          };
        });
      });
    };
    const onSubmit = () => {
      if (form.userName == "") {
        Notify({
          type: 'warning',
          message: '请输入用户名'
        });
        return;
      }
      if (form.password == "") {
        Notify({
          type: 'warning',
          message: '请输入密码'
        });
        return;
      }
      if (form.regionalId == null) {
        Notify({
          type: 'warning',
          message: '请输入所在部门'
        });
        return;
      }
      sessionStorage.setItem("regionalId", form.regionalId);
      let authorization = JSON.parse(sessionStorage.getItem("authorization"));
      let obj = {
        "wechatUnionid": authorization?.wechatAccountInfo?.unionid,
        "userName": form.userName,
        "password": form.password
      };
      loginH5Api(obj).then(res => {
        if (res.data.success) {
          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx423a03ebedebe0a0&redirect_uri=http%3A%2F%2Fems.laokenhealth.com%2Fdmh5&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        } else {
          Notify({
            type: 'warning',
            message: res.data.message
          });
        }
      });
    };
    onMounted(() => {
      sessionStorage.removeItem("regionalId");
      allUserRegionalFun();
    });
    return {
      headImg,
      form,
      columns,
      onConfirmPicker,
      showPicker,
      showPIckerType,
      onSubmit
    };
  }
};