// import { createConditionalExpression } from '@vue/compiler-core';
import axios from 'axios'
import {Notify} from 'vant'
import {baseURL} from './../../public/config'

const request = axios.create({
	timeout: 30000,
	baseURL: baseURL
});
request.interceptors.request.use(config=>{
	config.headers['Accept-Language'] = 'zh-cn';
	if(sessionStorage.getItem('token')){
		config.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`
	}

	config.headers['X-Regional'] = sessionStorage.getItem("regionalId")?sessionStorage.getItem("regionalId"):1;
	config.headers['X-SystemType'] = 2
	return config
},err => {
	return Promise.reject(err)
})

request.interceptors.response.use(response => {
	if(response.status===200){
		if(response.data.code ==200){
			return response
		}else{
			Notify({type:'danger',message:response.data.message})
			return Promise.reject(response);
		}
	}
},error => {
	if(error.response.status){
		switch(error.response.status){
			case 401:
				Notify({type:'danger',message:'没有权限!'})
			}
		}
	// Notify({type:'danger',message:'没有权限!'})
	return Promise.reject(error);
})



export const http = (config) => {
    return request(config)
}
export default request